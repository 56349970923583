export default {
  conductivity: {
    'mS/cm': (value) => parseFloat(value).toFixed(2), // unit used on HydroE
    'μS/cm': (value) => (Math.round(value * 10) * 100).toFixed(0),
    CF: (value) => Math.round(value * 10.0).toFixed(0),
    ppmUS: (value) => (Math.round(value * 50) * 10).toFixed(0),
    ppmEU: (value) => (Math.round(value * 6.4) * 100).toFixed(0),
    ppmAU: (value) => (Math.round(value * 7.0) * 100).toFixed(0),
  },

  temperature: {
    '°C': (value) => parseFloat(value).toFixed(1), // unit used on HydroE
    '°F': (value) => ((value * 9) / 5 + 32).toFixed(1),
  },

  volume: {
    L: (value) => parseFloat(value).toFixed(0), // unit used on HydroE
    Gallon: (value) => (value * 0.2641729).toFixed(1),
  },

  flux: {
    ' ': (value) => value,
  },

  reverseConductivity: {
    'mS/cm': (value) => parseFloat(value).toFixed(1), // unit used on HydroE
    'μS/cm': (value) => (value / 1000.0).toFixed(0),
    CF: (value) => (value / 10.0).toFixed(0),
    ppmUS: (value) => (value / 500.0).toFixed(0),
    ppmEU: (value) => (value / 640.0).toFixed(0),
    ppmAU: (value) => (value / 700.0).toFixed(0),
  },

  reverseTemperature: {
    '°C': (value) => parseFloat(value).toFixed(1), // unit used on HydroE
    '°F': (value) => (((value - 32) * 5) / 9).toFixed(1),
  },

  reverseVolume: {
    L: (value) => parseFloat(value).toFixed(0), // unit used on HydroE
    Gallon: (value) => Math.round(value / 0.2641729).toFixed(1),
  },
  reverseFlux: {
    ' ': (value) => parseFloat(value).toFixed(0),
  },
};
