<template>
  <div>
    <v-container>
      <v-row align="center" class="pl-4 mx-4 my-4">
        <v-col v-for="(sensor, index) in sensors" :key="index">
          <v-card min-width="160px" max-width="200px" class="mx-n2">
            <div class="headerClass">
              <v-card-title
                class="font-weight-light text-sm-h6 text-body-2"
                :style="{ background: sensor.color }"
              >
                {{ sensor.label }}
              </v-card-title>
            </div>
            <v-card-text class="mt-4 ml-6 text-h4">
              <!-- {{ $store.state.sensors[sensor.key] }} -->
              {{ setUnit(sensor.type, sensor.unitType) }}
              <span class="text-body-1">
                {{ $store.state.units[sensor.unitType] }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import quantities from '@/components/units';

export default {
  methods: {
    setUnit(type, unitType) {
      const unit = this.$store.state.units[unitType];
      let value = this.$store.state.sensors[type];
      if (typeof value === 'undefined' || value.isNaN) {
        value = 0;
      }
      if (typeof unit !== 'undefined') {
        // only flux is undefined atm
        return quantities[unitType][unit](value);
      }
      return value;
    },
  },
  data: () => ({
    sensors: [
      {
        label: 'Conductivity',
        type: 'conductivity',
        value: 1.83,
        fixed: 2,
        unitType: 'conductivity',
        color: '#67f267',
      },
      {
        label: 'Water Temperature',
        type: 'water_temperature',
        value: 23.2,
        fixed: 1,
        unitType: 'temperature',
        color: '#67ccf2',
      },
      {
        label: 'Air Temperature',
        type: 'air_temperature',
        value: 23.5,
        fixed: 1,
        unitType: 'temperature',
        color: '#dedede',
      },
      {
        label: 'Sunlight',
        type: 'light_flux',
        value: 25674,
        fixed: 0,
        unitType: 'flux',
        color: '#f7a664',
      },
    ],
  }),
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
