// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import VueMarkdown from '@adapttive/vue-markdown';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

// import VueSocketio from 'vue-socket.io'

// Vue.use(VueSocketio, '192.168.1.148:8000', store)
// Vue.use(VueSocketio, '192.168.1.9:8000', store) // 'localhost:8000', store)

// Vue.use(VueMarkdown);
Vue.config.productionTip = false;

new Vue({
  // eslint-disable-line no-new
  // el: '#app',
  router,
  store,
  vuetify,

  // template: '<App/>',
  // components: { App },
  render: (h) => h(App),
}).$mount('#app');
