<template lang="html">
  <div>
    <v-card
      class="mt-10"
      :width="isExpanded ? wide + 180 + 'px' : narrow + 180 + 'px'"
    >
      <v-card-title class="chart-header grey--text text--darken-1">
        {{ chartType.replace(/_/g, ' ') }}
        <v-spacer></v-spacer>
        <v-btn icon color="pink" class="show" @click="expand()">
          <v-icon color="salmon" v-show="!isExpanded">
            mdi-chevron-right
          </v-icon>
          <v-icon color="salmon" v-show="isExpanded">mdi-chevron-left</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="chart-block" v-show="show">
        <div class="" :id="chartType"></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import * as vega from 'vega';
import vegaEmbed from 'vega-embed';
import quantities from '@/components/units';

export default {
  data: () => ({
    view: {},
    onResize: '',
    spec: {},
    values: [],
    narrow: 320,
    wide: 800,
    show: true,
    // height: 300,
    newData: true,
    isExpanded: false,
    unit: '',
    opt: {
      mode: 'vega-lite',
      renderer: 'svg',
      actions: {
        export: true,
        source: false,
        editor: false,
        compiled: false,
      },
      padding: {
        left: 0,
        top: 10,
        right: 20,
        bottom: 20,
      },
    },
  }),
  props: ['chartType', 'points', 'height', 'dates'],
  methods: {
    expand() {
      if (this.isExpanded) {
        this.isExpanded = false;
        this.view.width(this.narrow);
      } else {
        this.isExpanded = true;
        this.view.width(this.wide);
      }
    },
    units(value) {
      let unitValue = 0.0;
      if (this.chartType === 'conductivity') {
        this.unit = this.$store.state.units.conductivity;
        unitValue = quantities.conductivity[this.unit](value);
      } else if (
        this.chartType === 'air_temperature'
        || this.chartType === 'water_temperature'
      ) {
        this.unit = this.$store.state.units.temperature;
        unitValue = quantities.temperature[this.unit](value);
      } else if (this.chartType === 'water_level') {
        this.unit = this.$store.state.units.volume;
        unitValue = quantities.volume[this.unit](value);
      } else if (this.chartType === 'volume_nutrients') {
        this.unit = 'ml';
        unitValue = value;
      } else {
        unitValue = value;
      }
      return unitValue;
    },
  },
  mounted() {},
  beforeMount() {
    // add in listener to make it dynamic for each chart type
    this.$options.sockets.onmessage = async (packet) => {
      const [event, data] = JSON.parse(packet.data);
      if (event === this.chartType) {
        // console.log('WWW', event, data, this.dates); // dates is a prop of Graphs.vue
        if (data !== 'end') { // push data onto values until 'end' received
          this.values.push(...data);
        } else { // when end received map specData and then draw graph
          let specData;
          if (this.chartType === 'water_level') {
            specData = this.dates.map((d, index) => ({
              y: this.units(this.values[index]) * (this.$store.state.settings['Valve Flow'][0] / 1000),
              date: d,
            }));
          } else if (this.chartType === 'air_temperature') { // add correction for pcb heating
            specData = this.dates.map((d, index) => ({
              y: this.units(this.values[index]) - 5,
              date: d,
            }));
          } else {
            specData = this.dates.map((d, index) => ({
              y: this.units(this.values[index]), // convert to correct selected unit value see above
              date: d,
            }));
          }
          console.log('SPEC', event, this.values, specData);
          // eslint-disable-next-line import/no-dynamic-require, global-require
          this.spec = require(`@/vega/${this.chartType}.vl.json`); // vega graph
          this.spec.data.values = specData.map((v) => ({ ...v }));
          const title = this.spec.vconcat[1].encoding.y.axis.title.split('(');
          console.log('VAAAAAAL', this.spec.data.values);
          if (title.length === 2) {
            this.spec.vconcat[1].encoding.y.axis.title = `${title[0]} (${this.unit})`;
          }
          const result = await vegaEmbed(`#${this.chartType}`, this.spec, this.opt);
          this.view = result.view;
          this.view.width(this.narrow);
        }
        /*
          ** ways to update graph with new data

          let newData = this.view.data('table').slice();
          console.log('newedata', newData);
          newData = newData.concat(values);
          console.log('NEWENWE', event, newData);
          this.view.data('table', newData);
          await this.view.runAsync();

          ** OR

          await this.view
            .change(
              'table',
              vega
                .changeset()
                .insert(values)
                // eslint-disable-next-line no-unused-vars
                .remove((_) => false),
            )
            .runAsync(); // update with new data

        */
      }
    };
  },
  beforeUnmount() {
    console.log('deleting', this.chartType);
    delete this.$option.sockets.onmessage;
    this.data.length = 0;
    this.newData = true;
    this.values.length = 0;
    this.view.finalize(); // removes event listeners and removes view
  },
};
</script>
