<template>
  <v-app>
    <navbar />
    <!-- <v-main> -->
      <v-sheet class="pb-12"/>
      <home class="pt-16"/>
    <!-- </v-main> -->
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Home from '@/views/Home.vue';

export default {
  name: 'App',
  components: { Navbar, Home },

  data: () => ({
    //
  }),
};
</script>
