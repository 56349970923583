<template>
  <div>
    <v-row justify="center">
      <v-col cols="3" offset="5">
        <v-btn
          text
          color="secondary"
          class="primary--text text--darken-4 mt-10"
          @click.native="saveSettings()"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="pb-14">
      <v-col cols="auto">
        <v-list
          color="primary"
          width="100%"
          max-width="550px"
          max-height="550px"
          class="overflow-y-auto lighten-2"
        >
          <!-- <v-list-item-group class="my-10"> -->
          <v-list-item
            v-for="(value, name, index) of $store.state.settings"
            :key="index"
            dense
          >
            <v-list-item-content
              class="px-2"
              v-text="name"
              text-h4
            ></v-list-item-content>
            <v-list-item-content>
              <v-row dense>
                <v-col md="5" cols="4">
                  <v-text-field
                    dense
                    v-model="value[0]"
                    class="shrink"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-content v-text="value[1]"></v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Units from '@/components/units';

export default {
  data: () => ({
    psocOrdered: [
      'Conductivity',
      'Tank Size',
      'Nutrient Constant',
      'Pstaltic Flow',
      'Valve Flow',
      'Too Hot',
      'Too Humid',
      'Pump on Time',
      'Pump off Time',
      'Cond slope',
      'Cond offset',
      'Nutrient Interval',
    ],
  }),
  mounted() {
    // This is now shifted to store/index.js so Valve Flow value is available to water_level chart
    // this.$socket.send('["settings", ""]');
  },
  methods: {
    saveSettings() {
      const settings = [];
      this.psocOrdered.forEach((key) => {
        const [value, unit] = this.$store.state.settings[key];
        if (key === 'Conductivity') {
          settings.push(Units.reverseConductivity[unit](value));
        } else if (key === 'Tank Size') {
          settings.push(Units.reverseVolume[unit](value));
        } else if (key === 'Too Hot') {
          settings.push(Math.round(Units.reverseTemperature[unit](value)));
        } else {
          settings.push(value); // json as float rather than string
        }
      });
      console.log('SSSS', `["save_settings", ${JSON.stringify(settings)}]`);
      this.$socket.send(`["save_settings", ${JSON.stringify(settings)}]`);
    },
    snake(text) {
      if (!text) return '';
      return text.toLowerCase().replace(/ /g, '_');
    },
  },
};
</script>
