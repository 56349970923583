<template>
  <div>
    <v-app-bar
      app
      dark
      fade-img-on-scroll
      hide-on-scroll
      src="@/images/gears-small_alien.jpg"
      color="accent"
      flat
      light
      height="150"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top, rgba(255,255,255,1.0), rgba(165,132,172,0.8)"
        ></v-img>
      </template>
      <div class="d-flex align-center">
        <a href="https://elasticgreen.com">
          <v-row>
            <v-col>
              <v-img
                alt="Logo"
                class="shrink mr-2"
                contain
                src="@/assets/ego_logo.svg"
                transition="fade-transition"
                width="40"
              />
            </v-col>
            <v-col>
              <v-img
                alt="ElasticGreen"
                class="mt-1"
                contain
                min-width="100"
                src="@/assets/ego_camel.svg"
                width="200"
              />
            </v-col>
          </v-row>
        </a>
        <div class="ml-10"></div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      tab: null,
    };
  },
};
</script>

<style lang="sass">
.rotating
  transition: transform 4s ease-in-out
.rotating:hover
  transform: rotateZ(720deg) scale(1.3)
</style>
