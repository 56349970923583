<template>
  <div>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="dialog"
          hide-overlay
          transition="dialog-top-transition"
          width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              class="primary--text text--darken-2"
            >
              <v-icon v-text="icon"></v-icon>
              <div class="ml-5 text-h5 font-weight-light">{{ text }}</div>
            </v-btn>
          </template>
          <v-card color="primary" class="lighten-2" height="700px">
            <v-card-title>
              <h2 class="primary--text text--darken-3">Units</h2>
            </v-card-title>
            <v-divider class="mb-8"></v-divider>
            <v-card-text>
              <div v-for="(unitType, index) in unitTypes" :key="index">
                <h2 class="font-weight-light text-capitalize">{{ index }}</h2>
                <v-radio-group v-model="units[index]" class="small-radio">
                  <v-radio
                    v-for="unit in unitType"
                    :key="unit"
                    :label="unit"
                    :value="unit"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    text
                    color="secondary"
                    class="primary--text text--darken-4"
                    @click="
                      saveUnits();
                      dialog = false;
                    "
                  >
                    Save
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    text
                    color="secondary"
                    class="primary--text text--darken-4"
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import quantities from '@/components/units';

export default {
  data: () => ({
    dialog: false,
    units: {
      conductivity: 'mS/cm',
      temperature: '°C',
      volume: 'L',
      // 'flux': ' ',
    },
    // conductivity: 'CF',
    rbuttons: [],
    unitTypes: null,
  }),
  props: ['icon', 'text'],
  methods: {
    saveUnits() {
      console.log('SU', this.units);
      this.$store.commit('updateUnits', this.units);
    },
  },
  beforeMount() {
    this.unitTypes = {
      conductivity: Object.keys(quantities.conductivity),
      temperature: Object.keys(quantities.temperature),
      volume: Object.keys(quantities.volume),
      // flux: Object.keys(quantities.flux),
    };
    this.units = this.$store.state.units;
  },
};
</script>

<style scoped>
* {
  text-transform: none !important;
}
</style>
