<template>
  <v-row justify="center">
    <v-col cols="auto" class="my-12">
      <v-list
        color="primary"
        class="lighten-2"
        width="100%"
        max-width="450px"
        min-height="400px"
      >
        <v-list-item v-for="(preference, index) in preferences" :key="index">
          <component
            :is="preference.component"
            :icon="preference.icon"
            :text="preference.text"
          ></component>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import Units from './Units.vue';
import DBDelete from './DBDelete.vue';

export default {
  data: () => ({
    dialog: false,
    display: null,
    preferences: [
      {
        text: 'Choose Units',
        icon: 'mdi-ruler',
        component: Units,
      },
      {
        text: 'Delete Saved Sensor Data',
        icon: 'mdi-delete',
        component: DBDelete,
      },
    ],
  }),
  methods: {
    showDialog(component) {
      this.display = component;
      this.dialog = true;
      console.log('here');
    },
  },
  components: { Units, DBDelete },
};
</script>
