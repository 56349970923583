<template lang="">
  <v-row class="my-10" justify="center">
    <!-- <v-col class="ml-5"></v-col> -->
    <v-spacer></v-spacer>
    <v-col cols="auto">
      <v-chip
        class="px-5 white--text text-h6 text-sm-h5"
        :color="$store.state.pump_off ? 'red' : 'green'"
      >
        Pump {{$store.state.pump_off ? 'off' : 'on'}}
      </v-chip>
    </v-col>
    <v-col>
      <v-chip
        class="px-5 white--text text-h6 text-sm-h5"
        :color="$store.state.controller_off ? 'red' : 'green'"
      >
        Controller {{$store.state.controller_off ? 'off' : 'on'}}
      </v-chip>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    controller_off: false,
    pump_off: false,
  }),
  mounted() {
    // this.$socket.send('["status", ""]');
  },
};
</script>
