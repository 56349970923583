import Vue from 'vue';
import Vuex from 'vuex';
import Units from '@/components/units';

Vue.use(Vuex);

const servers = JSON.parse(localStorage.getItem('servers'));
const units = JSON.parse(localStorage.getItem('units'));
const ordered = [
  'Conductivity',
  'Tank Size',
  'Nutrient Constant',
  'Pstaltic Flow',
  'Valve Flow',
  'Pump on Time',
  'Pump off Time',
  'Nutrient Interval',
  'Cond offset',
  'Cond slope',
  'Too Hot',
  'Too Humid',
];

function snake(text) {
  if (!text) return '';
  return text.toLowerCase().replace(/ /g, '_');
}

export default new Vuex.Store({
  state: {
    servers: servers ? servers || null : null,
    sensors: { },
    units: units
      ? units || null
      : {
        conductivity: 'mS/cm',
        temperature: '°C',
        volume: 'L',
        flux: ' ',
      },
    settings: {},
    server: { ip: '', name: '' },
    connected: false,
    alert: { connect: false },
    pump_off: false,
    controller_off: false,
  },
  mutations: {
    updateSensors(state, sensors) {
      console.log('PPP', sensors);
      state.sensors = sensors;
      state.sensors.air_temperature -= 5; // correction for heating from electronics
    },
    updateServers(state, data) {
      Object.assign(state.servers, data);
      localStorage.setItem('servers', JSON.stringify(state.servers));
      // console.log('SERVERS', data, state.servers);
    },
    updateUnits(state, data) {
      state.units = data;
      localStorage.setItem('units', JSON.stringify(state.units));
    },
    updateSettings(state, data) {
      const newValues = {};
      let value;
      let unit;
      ordered.forEach((key) => {
        [value, unit] = data[snake(key)];
        if (key === 'Conductivity') {
          unit = state.units.conductivity;
          newValues[key] = [Units.conductivity[unit](value), unit];
        } else if (key === 'Tank Size') {
          unit = state.units.volume;
          newValues[key] = [Units.volume[unit](value), unit];
        } else {
          newValues[key] = [value, unit];
        }
      });
      state.settings = { ...newValues }; // Need this to update (reactive)
      console.log('SETTINGS', data, state.settings);
    },
    updateStatus(state, data) {
      state.pump_off = data.pump_off;
      state.controller_off = data.controller_off;
    },
    removeServer(state, ip) {
      Vue.delete(state.servers, ip); // Special Vue.delete for object
      localStorage.setItem('servers', JSON.stringify(state.servers));
    },
    connected(state) {
      state.connected = !state.connected;
    },
    SOCKET_ONERROR(state, packet) {
      console.error('SOCKET_ONERROR', state, 'hehe', packet);
      state.alert.connect = true;
    },
    // eslint-disable-next-line no-unused-vars
    SOCKET_ONOPEN(state, _packet) {
      state.connected = true;
      Vue.prototype.$socket.send('["hostname", ""]'); // retrieve hostname
      Vue.prototype.$socket.send('["settings", ""]'); // need settings for graph water_level valve flow rate
    },
    SOCKET_ONMESSAGE(state, packet) {
      const [event, arg] = JSON.parse(packet.data);
      // console.log('ZZ', event, arg);
      if (event === 'hostname') {
        state.server.name = arg;
        // eslint-disable-next-line prefer-destructuring
        state.server.ip = packet.origin.match(/ws:\/\/(.*?):/)[1];
        // below use square brackets for computed property name
        state.servers = { ...state.servers, [state.server.ip]: arg };
        localStorage.setItem('servers', JSON.stringify(state.servers));
        // console.log(state.servers);
      } else if (event === 'sensor_readings') {
        this.commit('updateSensors', arg);
      } else if (event === 'settings') {
        this.commit('updateSettings', arg);
      } else if (event === 'status') {
        this.commit('updateStatus', arg);
      } else {
        // empty
      }
    },
    SOCKET_RECONNECT(state, packet) {
      console.log('SOCKET_RECONNECT', state, packet);
    },
    SOCKET_RECONNECT_ERROR(state, packet) {
      console.log('SOCKET_RECONNECT_ERROR', state, packet);
    },
    SOCKET_ONCLOSE(state, packet) {
      console.log('SOCKET_CLOSE', state, packet);
    },
  },
  getters: {
    convertUnits: (state) => (value, unitType) => {
      console.log('HHHH', Units.conductivity, state, value, unitType);
      return 1;
    },
  },
  actions: {
    sendMessage: (context, message) => {
      Vue.prototype.$socket.send(message);
    },
  },
  modules: {},
});
