<template lang="html">
  <div>
    <v-row class="chart">
      <v-col v-for="chart in charts" :key="chart">
        <chart :chartType="chart" :points="points" :dates="dates">></chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Chart from './Chart.vue';

export default {
  data: () => ({
    dates: [],
    points: 1500,
    charts: [
      'conductivity',
      'volume_nutrients',
      'air_temperature',
      'light_flux',
      'water_temperature',
      'water_level',
      // 'relative_humidity'
    ],
  }),
  components: {
    Chart,
  },
  mounted() {
    this.$socket.send('["sensor_DB", ""]');
  },
  beforeMount() {
    this.$options.sockets.onmessage = async (packet) => {
      const [event, data] = JSON.parse(packet.data);
      if (event === 'date') {
        // console.log('mmmm', data);
        // epoch date in s not ms
        // this.dates.push(...data.map((x) => new Date(x * 1000).toISOString()));
        if (data !== 'end') {
          this.dates.push(...data.map((x) => new Date(x * 1000)
            .toISOString()));
        } else {
          console.log('DATES', this.dates);
        }
        // .replace('T', ' ')));
        // .replace(/:\d\d.000Z/, '')
        // .replace('00:00', '11:04')));
      }
    };
  },
  beforeUnmount() {
    delete this.$options.sockets.onmessage;
    this.dates.length = 0;
  },
};
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  flex-wrap: wrap;
}
</style>
