<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-form v-model="valid" ref="form" class="mx-12 mt-10">
        <v-container v-if="!$store.state.connected">
          <template v-for="(name, ip) in $store.state.servers">
            <v-row :key="ip">
              <v-col cols="6" class="text-h6 font-weight-light">
                {{ name }}
              </v-col>
              <v-col cols="3">
                <v-btn
                  class="primary--text text--darken-4"
                  @click="connect(ip)"
                >
                  Connect
                </v-btn>
              </v-col>
              <v-col cols="3" class="pl-10">
                <v-btn icon color="red" @click="remove(ip)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row :key="'a' + ip"><v-divider></v-divider></v-row>
          </template>
          <v-row>
            <v-col cols="auto">
              <v-alert
                dense
                type="error"
                dismissible
                :value="$store.state.alert.connect && !firefox_hack"
              >
                Could not connect to device
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="ip"
                label="ip address"
                maxlength="15"
                :rules="ipRules"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                :disabled="!valid"
                class="mt-4 primary--text text--darken-4"
                @click="
                  validate;
                  connect(ip);
                "
              >
                Connect
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-center primary--text text-h5 text--darken-3"
            >
              <div>{{ $store.state.server.name }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                class="mt-4 primary--text text--darken-3"
                @click="disconnect()"
              >
                <v-icon class="mr-4">mdi-wifi</v-icon>
                Disconnect
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
// Note this component needs to be <keep-alive> otherwise websocket connection broken
import Vue from 'vue';
import VueNativeSocket from 'vue-native-websocket';
import store from '@/store';

Vue.use(VueNativeSocket, 'ws://localhost:9090', {
  connectManually: true,
  store,
});

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  data: () => ({
    value: 'sensors',
    alert: { connect: false },
    valid: true,
    ip: '192.168.1.100',
    // Fix in Firefox for the error "The connection to ws:... was interrupted
    // while the page was loading".
    firefox_hack: false,
    ipRules: [
      (value) => !!value || 'Required.',
      (value) => {
        const pattern = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
        return pattern.test(value) || 'Invalid ip';
      },
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    remove(ip) {
      this.$store.commit('removeServer', ip);
    },
    connect(ip) {
      // console.log(this.$options);
      this.firefox_hack = false;
      this.$store.state.alert.connect = false;
      const wsip = `ws://${ip}:8765`;
      this.$connect(wsip);
    },
    disconnect() {
      this.$disconnect(); // vue-native-websocket disonnect
      this.firefox_hack = true;
      this.$store.commit('connected'); // toggle connected state
    },
    beforeMount() {
      this.disconnect();
    },
  },
};
</script>
