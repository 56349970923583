<template lang="">
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-top-transition"
      width="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          class="primary--text text--darken-2"
        >
          <v-icon v-text="icon"></v-icon>
          <div class="ml-5 text-h5 font-weight-light">{{ text }}</div>
        </v-btn>
      </template>
      <v-card color="primary" class="lighten-2 height=500px">
        <v-card-title>
          <h2 class="primary--text text--darken-3">Delete sensor data</h2>
        </v-card-title>
        <v-divider class="mb-8"></v-divider>
        <v-card-text class="warning--text text-h6 font-weight-bold">
          This will delete all saved sensor data. Do you want to continue?
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                text
                color="secondary"
                class="primary--text text--darken-4"
                @click.native="
                  deleteData();
                  dialog = false;
                "
              >
                Delete Data
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                text
                color="secondary"
                class="primary--text text--darken-4"
                @click.native="dialog = false"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: ['text', 'icon'],
  methods: {
    deleteData() {
      this.$socket.send('["delete_data", ""]');
    },
  },
};
</script>

<style scoped lang=scss>
* {
  text-transform: none !important;
}
</style>
