<template>
  <div class="mx-auto">
    <v-card
      color="grey"
      class="flex-column mx-2 my-14 lighten-4"
      :width="title === 'Graphs' ? '100%' : '500px'"
      shaped
    >
      <v-card-title class="grey lighten-3 grey--text">
        {{ title }}
       <v-spacer></v-spacer> <v-col class="primary--text">{{ $store.state.server.name }}</v-col>
      </v-card-title>
      <v-card-text>
        <component :is="top"></component>
      </v-card-text>
      <v-divider></v-divider>
      <div class="pb-5">
        <component :is="bottom"></component>
      </div>
      <!-- <v-spacer></v-spacer> -->
      <v-bottom-navigation
        background-color="accent"
        color="primary"
        v-model="value"
      >
        <v-btn
          v-for="(screen, index) in screens"
          :disabled="!$store.state.connected"
          :key="index"
          :value="screen.value"
          @click="
            top = screen.top;
            bottom = screen.bottom;
            title = screen.text;
          "
        >
          <span>{{ screen.text }}</span>
          <v-icon>{{ screen.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-card>
  </div>
</template>

<script>
import Connect from './Connect.vue';
import Sensors from './Sensors.vue';
import Graph from './Graphs.vue';
import Settings from './Settings.vue';
import Preferences from './Preferences.vue';
import Status from './Status.vue';

export default {
  data: () => ({
    top: null,
    bottom: Connect,
    value: 'connect',
    title: 'Connect',
    connected: true,
    screens: [
      {
        value: 'connect',
        text: 'Disconnect',
        icon: 'mdi-connection',
        top: null,
        bottom: Connect,
      },
      {
        value: 'sensors',
        text: 'Sensors',
        icon: 'mdi-memory',
        top: Sensors,
        bottom: Status,
      },
      {
        value: 'graphs',
        text: 'Graphs',
        icon: 'mdi-chart-line',
        top: Graph,
        bottom: null,
      },
      {
        value: 'settings',
        text: 'Settings',
        icon: 'mdi-cogs',
        top: Settings,
        bottom: null,
      },
      {
        value: 'preferences',
        text: 'Preferences',
        icon: 'mdi-account-cog',
        top: Preferences,
        bottom: null,
      },
    ],
  }),
  components: {
    Connect,
    Graph,
    Settings,
    Preferences,
  },
  watch: {
    '$store.state.connected': function () {
      // this.top = this.$store.state.connected ? Sensors : Connect;
      console.log('CCCNNNCCT');
      this.top = this.$store.state.connected ? Sensors : null;
      this.bottom = this.$store.state.connected ? Status : Connect;
      this.title = this.$store.state.connected ? 'Sensors' : 'Connect';
      this.value = 'sensors';
    },
  },
};
</script>

<style lang="css">
</style>
